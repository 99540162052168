import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IIdentity, ILogin, IResetPassword, ISetNewPassword } from '../interfaces';
import { Observable } from 'rxjs';
import { LocalStorageService, NavigationService } from '@cinetixx/cinetixx-ui';
import { LanguageStoreService } from '../../../language/core/services/LanguageStoreService';
import { IdentityService } from 'src/app/core/services/IdentityService';
import { TokenService } from '../../../core/services/TokenService';
import { ApiService } from '../../../core/services/ApiService';
import { AbstractSignalRService } from '../../../core/services/AbstractSignalRService';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly _basePath = '/employees';

  private readonly _apiService = inject(ApiService);
  private readonly _tokenService = inject(TokenService);
  private readonly _identityService = inject(IdentityService);
  private readonly _router = inject(Router);
  private readonly _localStorageService = inject(LocalStorageService);
  private readonly _languageStoreService = inject(LanguageStoreService);
  private readonly _navigationService = inject(NavigationService);

  public login(body: ILogin): Observable<IIdentity> {
    return this._apiService.post(`${ this._basePath }/login`, body);
  }

  public getStatus(): Observable<string> {
    return this._apiService.get(`${ this._basePath }/status`);
  }

  public refreshToken(): Observable<IIdentity> {
    return this._apiService.post(
      `${ this._basePath }/refresh-token`,
      {
        refreshToken: this._tokenService.refreshToken
      }
    );
  }

  public revokeTokens(): void {
    this._apiService.post(
      `${ this._basePath }/logout`,
      {
        refreshToken: this._tokenService.refreshToken
      }
    ).subscribe();
  }

  public resetPassword(data: IResetPassword): Observable<void> {
    return this._apiService.post(`${this._basePath}/reset-password`, data);
  }

  public setPassword(data: ISetNewPassword): Observable<void> {
    return this._apiService.post(`${this._basePath}/set-password`, data);
  }

  public purgeAuth(): void {
    if (this._identityService.identity) {
      this.revokeTokens();
    }

    this.logout(false);
  }

  public authWithToken(token): Observable<IIdentity> {
    return this._apiService.post(
      `${ this._basePath }/login-with-token`,
      {
        token,
      }
    );
  }

  public logout(withRedirect = true): void {
    AbstractSignalRService.connections.forEach(connection => connection.stop());
    this._identityService.destroyIdentity();
    
    if (withRedirect) {
      this._navigationService.navigateByUrl(
        `/auth/login?back=${ location.pathname.replace(`/${ this._languageStoreService.language.token }`, '') }`
      );

      location.reload();
    }

    this._localStorageService.clear();
  }
}
